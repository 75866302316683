import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";


@Injectable()
export class AutocompleteProvider {

    baseUrl: string = "https://api.inkit.com/v1/autocomplete"
    httpOptions: any;

    constructor(private http: HttpClient) {
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Inkit-Api-Token': 'MTE5MzozYzZlYTM5MzYxZGU0MGI3YTdkYjcyMzI5MmM0ZjdmMw==',
            'Access-Control-Allow-Origin': '*'
            
          })
          //withCredentials: true
        };
      }

      autocomplete(formdata: any): Observable<any> {
          const data = JSON.stringify(formdata);
          return this.http.post(this.baseUrl, data, this.httpOptions);
      }

}
