import {Component, OnInit, ViewChildren } from '@angular/core';
import {AutocompleteProvider} from './shared/autocomplete.provider'


declare var runit: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})



export class AppComponent implements OnInit {
  title = 'citizens';
  selected = {
    "address": {
      "address_line1": "",
      "address_line2": "",
      "address_city": "",
      "address_state": "",
      "address_zip": "",
      "address_country": ""
    }
  }
  results: any = []
  completed: boolean = true;

  constructor(private autocompleteProvider: AutocompleteProvider) {

  }

  ngOnInit() {
  }

  onSearchChange(event) {
    this.autocompleteProvider.autocomplete({"prefix": event}).subscribe(resp => {
      this.completed = false;
      this.results = resp['data'].slice(0, 5);
      console.log(this.results);
      console.log(this.completed)
    })
  }

  updateAddress(idx) {
    // Gets index of the array
    this.selected = this.results[idx];
    this.completed = true;
    console.log(this.completed)
  }

  retrieveRender (rendId, fileName) {
    
    const requestData = {
        method: "GET",
        headers: {"X-Inkit-API-Token": "MTE5MzozYzZlYTM5MzYxZGU0MGI3YTdkYjcyMzI5MmM0ZjdmMw=="},
        accept: "application/pdf"
  
  
    }
    fetch("https://api.inkit.com/v1/render/" + rendId + "/pdf", requestData).then(response => response.blob()).then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again         
    });
  }
}

